import { Component } from 'react';
import { AppProps } from 'single-spa';
import { TAppConf, TResource, TUser } from '@gf-tech/types';
import { http, store } from './utils';
import { RootRouter } from './components/RootRouter';
import styled from 'styled-components';
import { AliveController, withAliveScope } from 'react-activation';
import { EventBus, Log } from '@gf-tech/utils';
import apiCheckPackgeItem from './api/check-package/item';
import apiCompanyGrouping from './api/group-physical/company-grouping';

type TMenu = {
    old?: string;
    title: string;
    route: string;
    state?: Object;
};

type TState = {
    router: TResource;
    haNodes: { id: string; name: string }[];
    load: boolean;
    cachedMenus: TMenu[];
};

const RootStyle = styled.div`
    flex: 1;
    width: 100%;
    margin: var(--app-var-24);
    padding: 24px;
    padding-top: 8px;
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    height: calc(100% - var(--app-var-24) * 2);
    .ant-table-cell {
        .ant-btn-link {
            padding: 0 0;
            height: auto;
            + .ant-btn-link {
                margin-left: 8px;
            }
        }
    }
`;

class Root extends Component<AppProps & TAppConf & AliveController, TState> {
    constructor(props: AppProps & TAppConf & AliveController) {
        super(props);

        const appConf = props.appConf;
        store.namespaces(appConf.namespace);
        http.config({ baseURL: appConf.apiHost, store });
        const userInfo: TUser = store.get('userInfo');
        const pea = userInfo.resList.find(item => item.uriRoute === '/pea');
        this.state = {
            router: pea,
            haNodes: [],
            load: false,
            cachedMenus: [],
        };

        const _self = this;
        this.subscribeId = EventBus.subscribe(
            'app:tabMenus-change',
            (menus: TMenu[]) => {
                const menuSet = new Set(menus.map(item => item.route));
                this.cachedMenus.forEach(item => {
                    if (!menuSet.has(item.route)) _self.props.drop(item.route);
                });
                this.cachedMenus = menus;
            }
        );
    }

    subscribeId: string;

    cachedMenus: TMenu[] = [];

    componentWillUnmount() {
        EventBus.unsubscribe(this.subscribeId);
    }
    async componentDidMount() {
        await this.getHosp();
        if (!store.get('userInfo').enterpriseId) {
            this.mutexRules();
        }
        await this.getCheckCategoryList();
        await this.getHarmfulFactorsList();
    }
    async mutexRules() {
        let userInfo: TUser = store.get('userInfo');
        let res = await apiCheckPackgeItem.mutexRules({
            saasId: userInfo.saasCode,
        });
        let list = new Map<string, string[]>();
        res.forEach(item => {
            item.forEach(s => {
                if (list.has(s)) {
                    let others = item.filter(t => t !== s);
                    list.set(s, [...list.get(s), ...others]);
                } else {
                    let others = item.filter(t => t !== s);
                    list.set(s, others);
                }
            });
        });
        store.set('mutexRules', list);
    }
    async getHosp() {
        let userInfo: TUser = store.get('userInfo');
        let dataScope: string[] = userInfo.orgList.map(item => item.id);
        store.remove('selectHospId');
        try {
            let res = await apiCheckPackgeItem.getSaasInfo({
                saasCode: userInfo.saasCode,
            });
            let list = [{ id: 'null', name: '不限' }];
            let deptTree = [];
            if (!userInfo.currentHa) {
                list = list.concat(
                    res.haNodes.filter(item =>
                        userInfo.currentHa
                            ? item.id === userInfo.currentHa ||
                              item.platformId === userInfo.currentHa
                            : dataScope.indexOf(item?.platformId) > -1
                    )
                );
                deptTree = res.deptTree;
            } else {
                let currentHa = res.haNodes.find(
                    s =>
                        s.id == userInfo.currentHa ||
                        s?.platformId === userInfo.currentHa
                )?.id;
                store.set('userInfo', { ...userInfo, currentHa });
                list = res.haNodes.filter(item =>
                    userInfo.currentHa
                        ? item.id === userInfo.currentHa ||
                          item.platformId === userInfo.currentHa
                        : dataScope.indexOf(item?.platformId) > -1
                );
                deptTree = res.deptTree.filter(
                    s =>
                        s.id ===
                        res.haNodes.find(v => v.id === userInfo.currentHa)
                            ?.platformId
                );
            }
            this.setState(
                {
                    haNodes: list,
                    load: true,
                },
                () => {
                    store.set('deptTree', deptTree);
                    store.set('haNodes', this.state.haNodes);
                    store.set('haNodesAll', res.haNodes);
                    Log.log('院区', store.get('haNodes'));
                }
            );
        } catch (error) {
            this.setState(
                {
                    haNodes: [],
                    load: true,
                },
                () => {
                    store.set('haNodes', this.state.haNodes);
                    store.set('haNodesAll', []);
                }
            );
        }
    }
    //体检类别列表
    async getCheckCategoryList() {
        let res = await apiCompanyGrouping.getCheckCategoryList();
        store.set('checkCategoryList', res);
    }
    //有害因素列表
    async getHarmfulFactorsList() {
        let res = await apiCompanyGrouping.getHarmfulFactorsList();
        store.set('harmfulFactorsList', res);
    }
    render() {
        return (
            <RootStyle className='root-wrap'>
                {this.state.load && (
                    <RootRouter
                        base='/pea'
                        router={this.state.router}
                    ></RootRouter>
                )}
            </RootStyle>
        );
    }
}

export default withAliveScope(Root);
