import { IResList } from '@gf-tech/types';
import { http, store } from '../../../utils';
import {
    TCheckPackageItemQuery,
    TCheckPackageItemItem,
    TCheckPackageItemForm,
    TDeptTypeItem,
    TRelInfo,
    TSaasInfo,
    TDetailList,
    TOpltemAddQuery,
    TOpltemAddItem,
    TCheckMutext,
    TDetailItem,
    TDetailItemQuery,
} from '../../../models/check-package/item';
import { TDeptItem, TDeptQuery } from '../../../models/set-up/department';

const apiCheckPackgeItem = {
    // 体检项目组合列表
    itemGroupList(params: TCheckPackageItemQuery) {
        return http.get<IResList<TCheckPackageItemItem[]>>(
            `/msx-zyh/api/v1${
                store.get('userInfo').enterpriseId ? '/company' : ''
            }/itemGroup/list${
                store.get('userInfo').enterpriseId
                    ? '?token=' + store.get('token')
                    : ''
            }`,
            {
                params,
                headers: {
                    Authorization: store.get('userInfo').enterpriseId
                        ? ''
                        : store.get('token'),
                },
            }
        );
    },
    // 指定院区下的体检项目组合列表
    listByHospIds(params: TCheckPackageItemQuery) {
        return http.get<TCheckPackageItemItem[]>(
            `/msx-zyh/api/v1/itemGroup/listByHospIds`,
            {
                params,
            }
        );
    },
    // 新增体检项目组
    addItemGroup(data: TCheckPackageItemForm) {
        return http.post('/msx-zyh/api/v1/itemGroup/addItemGroup', data);
    },
    // 编辑体检项目组
    editItemGroup(data: TCheckPackageItemForm) {
        return http.post('/msx-zyh/api/v1/itemGroup/editItemGroup', data);
    },
    // 编辑体检细项
    editItemDetail(data: { id: string; detailList: TDetailList[] }) {
        return http.post('/msx-zyh/api/v1/itemGroup/editItemDetail', data);
    },
    // 查询体检项目组关联的细项
    groupRelDetail(id: string) {
        return http.get<TDetailList[]>(
            '/msx-zyh/api/v1/itemGroup/groupRelDetail',
            {
                params: { id },
            }
        );
    },
    // 回显体检组合基本信息
    backGroupInfo(id: string) {
        return http.get<TCheckPackageItemForm>(
            '/msx-zyh/api/v1/itemGroup/backGroupInfo',
            {
                params: { id },
            }
        );
    },
    // 科室类别选项
    deptTypeList(params: TDeptQuery) {
        return http.get<IResList<TDeptItem[]>>(
            '/msx-zyh/api/v1/itemGroup/deptList',
            {
                params,
            }
        );
    },
    // 关联检查耗材选项
    relCheckList(params: { hospId: string; saasId: string }) {
        return http.get<TRelInfo[]>('/msx-zyh/api/v1/itemGroup/relCheckList', {
            params,
        });
    },
    // 获取SAAS机构的省市区与内部院区列表
    getSaasInfo(params?: { saasCode?: string }) {
        return http.get<TSaasInfo>(
            '/msx-authentication/api/v1/basic/getSaasInfo',
            {
                params: {
                    ...params,
                    currentRes: '023',
                },
                headers: {
                    Authorization: store.get('userInfo').enterpriseId
                        ? ''
                        : store.get('token'),
                },
            }
        );
    },
    // 删除体检项目组
    delItemGroup(data: { ids: string[] }) {
        return http.post('/msx-zyh/api/v1/itemGroup/delItemGroup', data);
    },
    // 停用-排序体检项目组合
    stopItemGroup(data: { id: string; status?: boolean; sortCode?: number }) {
        return http.post('/msx-zyh/api/v1/itemGroup/stopItemGroup', data);
    },
    // 自选项目加项专项筛查列表
    opItemAddSpecialList(params: TOpltemAddQuery) {
        return http.get<TOpltemAddItem[]>(
            '/msx-zyh/api/v1/mobile/meal/opItemAddSpecialList',
            {
                params,
            }
        );
    },
    // 自选项目加项全部列表
    opItemAddAllList(params: TOpltemAddQuery) {
        return http.get<TOpltemAddItem[]>(
            '/msx-zyh/api/v1/mobile/meal/opItemAddAllList',
            {
                params,
            }
        );
    },
    // 校验该项目是否存在互斥
    checkMutexItem(params: TCheckMutext) {
        return http.get<string[]>('/msx-zyh/api/v1/meal/checkMutexItem', {
            params,
        });
    },
    mutexRules(params: { saasId: string }) {
        return http.get<Array<Array<string>>>(
            '/msx-zyh/api/v1/itemGroup/mutexRules',
            { params }
        );
    },
};
export default apiCheckPackgeItem;
