import { IResList } from '@gf-tech/types';
import { http } from '../../../utils';
import {
    TCompanyGroupingQuery,
    TCompanyGroupingItem,
    TCompanyGroupingForm,
    TCompanyGroupingGroupDto,
    TCompanyGroupingProjectList,
    TCompanyGroupingFixedList,
} from '../../../models/group-physical/company-grouping';

const apiCompanyGrouping = {
    // 分组列表
    getEnterpriseGroupList(params: TCompanyGroupingQuery) {
        return http.get<IResList<TCompanyGroupingItem[]>>(
            '/msx-zyh/api/v1/enterprise/getEnterpriseGroupList',
            {
                params,
            }
        );
    },
    // 新增-编辑分组
    addOrEditEnterpriseGroup(data: TCompanyGroupingForm) {
        return http.post(
            '/msx-zyh/api/v1/enterprise/addOrEditEnterpriseGroup',
            data
        );
    },
    // 批量删除分组
    delEnterpriseGroupByIds(data: { idList: string }) {
        return http.get('/msx-zyh/api/v1/enterprise/delEnterpriseGroupByIds', {
            params: data,
        });
    },
    //分组-下载模板
    downEnterpriseGroup() {
        return http.get('/msx-zyh/api/v1/enterprise/downEnterpriseGroup', {
            responseType: 'blob',
        });
    },
    // 分组-导入
    importEnterpriseGroup(data: FormData) {
        return http.post(
            '/msx-zyh/api/v1/enterprise/importEnterpriseGroup',
            data
        );
    },
    // 分组-导出
    exportEnterpriseGroup(params: {
        keywords?: string;
        enterpriseId?: string;
        chargeModel?: string;
        groupType?: string;
        saasId?: string;
    }) {
        return http.get('/msx-zyh/api/v1/enterprise/exportEnterpriseGroup', {
            responseType: 'blob',
            params,
        });
    },
    // 分组详情
    getEnterpriseGroupInfo(params: { id: string }) {
        return http.get<{
            phEnterpriseGroup: TCompanyGroupingGroupDto;
            mustList: TCompanyGroupingFixedList[];
            recommendList: TCompanyGroupingFixedList[];
            fixdList: TCompanyGroupingFixedList[];
        }>('/msx-zyh/api/v1/enterprise/getEnterpriseGroupInfo', {
            params,
        });
    },
    // 体检类别列表
    getCheckCategoryList() {
        return http.get<
            {
                categoryName: string;
                id: string;
            }[]
        >('/msx-zyh/api/v1/enterprise/getCheckCategoryList');
    },
    // 有害因素列表
    getHarmfulFactorsList() {
        return http.get<
            {
                factorName: string;
                id: string;
            }[]
        >('/msx-zyh/api/v1/enterprise/getHarmfulFactorsList');
    },
};
export default apiCompanyGrouping;
