import { TUser } from '@gf-tech/types';
import { Store, Http, IHttpAxiosInstance } from '@gf-tech/utils';

const store = new Store();

const http: IHttpAxiosInstance = new Http().create();
http.defaults.timeout = 60000;
http.interceptors.request.use(config => {
    let userInfo = store.get('userInfo');

    if (config.url.startsWith('/msx-zyh')) {
        if (!config.params) {
            config.params = {};
        }
        // config.params.hospId = config.params?.hospId || userInfo.currentHa;
        config.params.saasId = userInfo.saasCode;
        config.headers.saasId = userInfo.saasCode;
        //处理团检
        if (
            userInfo.enterpriseId &&
            (config.url.startsWith('/msx-zyh/api/v1/enterprise') ||
                config.url.startsWith('/msx-zyh/api/v1/ballCheck') ||
                config.url.startsWith('/msx-zyh/api/v1/medical/useList') ||
                config.url.startsWith('/msx-zyh/api/v1/medical/medicalList'))
        ) {
            if (config.url.startsWith('/msx-zyh/api/v1/enterprise')) {
                config.url = config.url.replace(
                    '/msx-zyh/api/v1/enterprise',
                    '/msx-zyh/api/v1/company'
                );
            }
            if (config.url.startsWith('/msx-zyh/api/v1/ballCheck')) {
                config.url = config.url.replace(
                    '/msx-zyh/api/v1/ballCheck',
                    '/msx-zyh/api/v1/groupCheck'
                );
            }
            if (config.url.startsWith('/msx-zyh/api/v1/medical/useList')) {
                config.url = config.url.replace(
                    '/msx-zyh/api/v1/medical/useList',
                    '/msx-zyh/api/v1/company/medical/useList'
                );
            }
            if (config.url.startsWith('/msx-zyh/api/v1/medical/medicalList')) {
                config.url = config.url.replace(
                    '/msx-zyh/api/v1/medical/medicalList',
                    '/msx-zyh/api/v1/company/medical/medicalList'
                );
            }
            config.url = config.url + '?token=' + store.get('token');
            config.headers.Authorization = '';
        }
        if (config.params?.currentPage && config.params?.pageSize) {
            config.params.current = config.params.currentPage;
            config.params.size = config.params.pageSize;
            delete config.params.currentPage;
            delete config.params.pageSize;
        }
        if (config.params?.sort === true || config.params?.sort === false) {
            config.params.desc = config.params.sort;
            delete config.params.sort;
        }
    }
    return config;
});

http.interceptors.response.use((response: any) => {
    if (response && response.size && response.current) {
        response.currentPage = response.current;
        response.pageSize = response.size;
        delete response.current;
        delete response.size;
    }
    return response;
});

export { http, store };
